/** @jsx jsx */
import { useStaticQuery, graphql } from "gatsby"
import { jsx } from "theme-ui"
import { Grid, Box } from "@theme-ui/components"
import { ArticleList, FindSpace, TheLayout } from "x"

const ArticlesPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        craft {
          entries(section: [articles]) {
            ... on Craft_Articles {
              title
              uri
              slug
              postDate
              body {
                totalPages
                content
              }
              featuredImage {
                id
                url
                focalPoint {
                  x
                  y
                }
              }
            }
          }
        }
      }
    `
  )

  const posts = data.craft.entries
  return (
    <TheLayout seoTitle="The articles">
      <Grid
        gap={4}
        sx={{
          gridTemplateColumns: ["auto", null, "1fr 256px"],
        }}
      >
        <ArticleList items={posts} />
        <Box>
          <FindSpace />
        </Box>
      </Grid>
    </TheLayout>
  )
}

export default ArticlesPage
